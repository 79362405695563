import React from "react";
import styled from "styled-components";

export default function LeftCard({ data, defaultCharges }) {
  return (
    <Container>
      <div className="tip">{data.title}</div>
      <div className={`tip_grid ${data.charge ? "grids" : ""}`}>
        {data.charge && (
          <div className="tip_grid__button">
            ৳{defaultCharges[data.charge].toFixed(2)}
          </div>
        )}
        <input
          type="number"
          onChange={(e) => data.inputFunction(Number(e.target.value))}
          className="tip_grid__button"
          placeholder="Custom"
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  .tip {
    padding: 20px 0 10px;
    font-size: 16px;
    line-height: 100%;
    color: #657171;
  }
  .tip_grid {
    width: 100%;
    &.grids {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 16px;
      grid-column-gap: 14px;
    }
    &__button {
      padding: 12px;
      background: #00474b;
      border-radius: 6px;
      text-align: center;
      display: grid;
      place-items: center;
      font-size: 24px;
      line-height: 100%;
      color: #f5ffff;
      &:hover {
        background: #9fe8df;
        color: #00474b;
      }
      &:last-child {
        font-family: Space Mono;
        font-weight: bold;
        &:focus {
          text-align: right;
        }
        background: #f4f8fb;
        color: #00474b;
        width: 100%;
        outline: none;
        border: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::placeholder {
          font-family: Space Mono;
          font-weight: bold;
          color: #9ebbbd;
        }
      }
    }
  }
`;
