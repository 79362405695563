import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Footer from "./Components/Footer";
import Left from "./Components/Left";
import Note from "./Components/Note";
import Option from "./Components/Option";
import Right from "./Components/Right";

export default function App() {
  const defaultCharges = {
    BKASHAgent: 18.5,
    BKASHAtm: 14.9,
    ROCKETAgent: 16.7,
    ROCKETAtm: 9.0,
    NAGAD: 11.49,
    NAGADIslamic: 15.0,
  };

  const [showOption, setShowOption] = useState("all");

  const [bill, setBill] = useState(0);

  const [BkashAgent, setBkashAgent] = useState(defaultCharges.BKASHAgent);
  const [BkashATM, setBkashATM] = useState(defaultCharges.BKASHAtm);
  const [RocketAgent, setRocketAgent] = useState(defaultCharges.ROCKETAgent);
  const [RocketATM, setRocketATM] = useState(defaultCharges.ROCKETAtm);
  const [Nagad, setNagad] = useState(defaultCharges.NAGAD);
  const [NagadIslamic, setNagadIslamic] = useState(defaultCharges.NAGADIslamic);

  const [BkashAgentCharge, setBkashAgentCharge] = useState("0.00");
  const [BkashATMCharge, setBkashATMCharge] = useState("0.00");
  const [RocketAgentCharge, setRocketAgentCharge] = useState("0.00");
  const [RocketATMCharge, setRocketATMCharge] = useState("0.00");
  const [NagadCharge, setNagadCharge] = useState("0.00");
  const [NagadIslamicCharge, setNagadIslamicCharge] = useState("0.00");

  useEffect(() => {
    setBkashAgentCharge(
      bill !== 0 && BkashAgent !== 0
        ? (bill * (BkashAgent / 1000)).toFixed(2)
        : "0.00"
    );
  }, [bill, BkashAgent]);

  useEffect(() => {
    setBkashATMCharge(
      bill !== 0 && BkashATM !== 0
        ? (bill * (BkashATM / 1000)).toFixed(2)
        : "0.00"
    );
  }, [bill, BkashATM]);

  useEffect(() => {
    setRocketAgentCharge(
      bill !== 0 && RocketAgent !== 0
        ? (bill * (RocketAgent / 1000)).toFixed(2)
        : "0.00"
    );
  }, [bill, RocketAgent]);

  useEffect(() => {
    setRocketATMCharge(
      bill !== 0 && RocketATM !== 0
        ? (bill * (RocketATM / 1000)).toFixed(2)
        : "0.00"
    );
  }, [bill, RocketATM]);

  useEffect(() => {
    setNagadCharge(
      bill !== 0 && Nagad !== 0 ? (bill * (Nagad / 1000)).toFixed(2) : "0.00"
    );
  }, [bill, Nagad]);

  useEffect(() => {
    setNagadIslamicCharge(
      bill !== 0 && NagadIslamic !== 0
        ? (bill * (NagadIslamic / 1000)).toFixed(2)
        : "0.00"
    );
  }, [bill, NagadIslamic]);

  return (
    <Container>
      <Header>
        <img src="/logo.svg" alt="Logo Jim" />
      </Header>
      <div className="container">
        <Option showOption={showOption} setShowOption={setShowOption} />
        <div className="grid">
          <Left
            setBkashAgent={setBkashAgent}
            setBkashATM={setBkashATM}
            setRocketAgent={setRocketAgent}
            setRocketATM={setRocketATM}
            setNagad={setNagad}
            setNagadIslamic={setNagadIslamic}
            setBill={setBill}
            showOption={showOption}
            defaultCharges={defaultCharges}
          />
          <Right
            bill={bill}
            setBill={setBill}
            BkashAgentCharge={BkashAgentCharge}
            BkashATMCharge={BkashATMCharge}
            RocketAgentCharge={RocketAgentCharge}
            RocketATMCharge={RocketATMCharge}
            NagadCharge={NagadCharge}
            NagadIslamicCharge={NagadIslamicCharge}
            showOption={showOption}
          />
        </div>
        {showOption !== "blank" && <Note showOption={showOption} />}
      </div>
      <Footer />
    </Container>
  );
}

const Header = styled.header`
  display: flex;
  justify-content: center;
  padding: 63px 0;
  @media (max-width: 548px) {
    padding-top: 50px;
    padding-bottom: 39px;
  }
  img {
    width: 87px;
    height: 54px;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  padding-bottom: 50px;
  .container {
    background: #ffffff;
    border-radius: 27px;
    padding: 10px 33px 13px;
    max-width: 923px;
    margin: 0 auto auto;
    @media (max-width: 768px) {
      padding: 32px 0 0;
      border-radius: 27px 27px 16px 16px;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 31px;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 33px;
      }
    }
  }
`;
