import React from "react";
import styled from "styled-components";
import LeftCard from "./LeftCard";

export default function Left({
  setBkashAgent,
  setBkashATM,
  setRocketAgent,
  setRocketATM,
  setNagad,
  setNagadIslamic,
  setBill,
  showOption,
  defaultCharges,
  setBlank,
}) {
  const LEFT = [
    {
      filter: "bkash",
      title: "Bkash (Agent) per ৳1000",
      charge: "BKASHAgent",
      inputFunction: setBkashAgent,
    },
    {
      filter: "bkash",
      title: "Bkash (ATM) per ৳1000",
      charge: "BKASHAtm",
      inputFunction: setBkashATM,
    },
    {
      filter: "rocket",
      title: "Rocket (Agent) per ৳1000",
      charge: "ROCKETAgent",
      inputFunction: setRocketAgent,
    },
    {
      filter: "rocket",
      title: "Rocket (ATM) per ৳1000",
      charge: "ROCKETAtm",
      inputFunction: setRocketATM,
    },
    {
      filter: "nagad",
      title: "Nagad (Regular) 9 per ৳1000",
      charge: "NAGAD",
      inputFunction: setNagad,
    },
    {
      filter: "nagad",
      title: "Nagad (Islamic) per ৳1000",
      charge: "NAGADIslamic",
      inputFunction: setNagadIslamic,
    },
  ];

  return (
    <Container className="left">
      <div className="bill">Amount</div>
      <div className="input_1">
        <img src="/icon-taka.svg" alt="$" />
        <input
          type="number"
          id="BILL"
          placeholder="0"
          onChange={(e) => setBill(Number(e.target.value))}
        />
      </div>
      {LEFT &&
        Array.isArray(LEFT) &&
        LEFT.length > 0 &&
        React.Children.toArray(
          showOption === "all" ? (
            LEFT.map((data) => (
              <LeftCard data={data} defaultCharges={defaultCharges} />
            ))
          ) : showOption !== "blank" ? (
            LEFT.filter((element) => element.filter === showOption).map(
              (data) => <LeftCard data={data} defaultCharges={defaultCharges} />
            )
          ) : (
            <LeftCard
              data={{ title: "Custom Field", inputFunction: setBlank }}
              defaultCharges={defaultCharges}
            />
          )
        )}
    </Container>
  );
}

const Container = styled.section`
  padding: 17px 18px 17px 17px;
  border-radius: 16px;
  @media (max-width: 768px) {
    padding: 0;
    padding: 0 24px;
  }
  .bill {
    font-size: 16px;
    line-height: 100%;
    color: #657171;
    padding-bottom: 14px;
  }
  .input_1 {
    background: #f3f8fb;
    border-radius: 5px;
    border: 1px solid #f3f8fb;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 16.04px 5px 19.05px;
    width: 100%;
    &.error {
      border-color: #d08a7a;
    }
    img {
      width: auto;
      height: 24px;
    }
    input {
      flex: 1;
      background-color: transparent;
      border: none;
      font-size: 24px;
      color: #00474b;
      font-family: Space Mono;
      text-align: right;
      width: 100%;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:invalid {
        border-color: #d08a7a;
      }
      &::placeholder {
        color: #9ebbbd;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .tip {
    padding: 20px 0 10px;
    font-size: 16px;
    line-height: 100%;
    color: #657171;
  }
  .tip_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;
    grid-column-gap: 14px;
    width: 100%;
    &__button {
      padding: 12px;
      background: #00474b;
      border-radius: 6px;
      text-align: center;
      display: grid;
      place-items: center;
      font-size: 24px;
      line-height: 100%;
      color: #f5ffff;
      &:hover {
        background: #9fe8df;
        color: #00474b;
      }
      &:last-child {
        font-family: Space Mono;
        font-weight: bold;
        &:focus {
          text-align: right;
        }
        background: #f4f8fb;
        color: #00474b;
        width: 100%;
        outline: none;
        border: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::placeholder {
          font-family: Space Mono;
          font-weight: bold;
          color: #9ebbbd;
        }
      }
    }
  }
`;
