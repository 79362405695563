import styled from "styled-components";

export default function Footer() {
  return (
    <Container>
      Developed by{" "}
      <a
        href="https://ah-jim-seed.web.app"
        target="_blank"
        rel="noopener noreferrer"
      >
        Akhlak Hossain Jim
      </a>
    </Container>
  );
}

const Container = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 5px;
  width: 100%;
  text-align: center;
  a {
    color: #00474b;
  }
`;
