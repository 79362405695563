import React from "react";
import styled from "styled-components";

export default function Option({ showOption, setShowOption }) {
  const options = ["all", "bkash", "rocket", "nagad"];

  return (
    <Container>
      Choose what to show:{" "}
      <select
        value={showOption}
        onChange={(e) => setShowOption(e.target.value)}
      >
        {React.Children.toArray(
          options.map((option) => <option value={option}>{option}</option>)
        )}
      </select>
    </Container>
  );
}

const Container = styled.section`
  padding: 20px;
  text-align: center;
  & > select {
    padding: 5px 10px;
    text-transform: capitalize;
    background-color: #00474b;
    color: #f4f8fb;
    border: none;
    outline: none;
    border-radius: 0;
    & > option {
      border-radius: 0;
      padding: 10px;
    }
  }
`;
