import React from "react";
import styled from "styled-components";

export default function Note({ showOption }) {
  const Links = [
    {
      title: "bkash",
      url: "https://www.bkash.com/support/tariff-limits/tariff",
    },
    {
      title: "rocket",
      url: "https://www.dutchbanglabank.com/rocket/charges-and-fees.html",
    },
    {
      title: "nagad",
      url: "https://nagad.com.bd/services/service/?service=cash-out",
    },
  ];

  const updatedDate = "31/08/2022";

  return (
    <Container>
      Note: charges are last updated at: {updatedDate} view following charge
      page{" "}
      {React.Children.toArray(
        showOption === "all"
          ? Links.map((link) => (
              <>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>{" "}
              </>
            ))
          : Links.filter((el) => el.title === showOption).map((link) => (
              <>
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>{" "}
              </>
            ))
      )}{" "}
      for updated charges.
    </Container>
  );
}

const Container = styled.section`
  padding: 20px;
  color: #d08a7a;
  font-size: 14px;
  text-align: center;
  a {
    text-transform: capitalize;
  }
`;
