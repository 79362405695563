import React from "react";
import styled from "styled-components";

export default function RightCard({ bill, data }) {
  return (
    <Container>
      <div className="line_left">
        {data.title && <p className="name">/ {data.title}</p>}
        <p>Can CashOut</p>
        <p>Total Charge</p>
      </div>
      <div className="line_right">
        <p>৳{bill - data.calculate}</p>
        <p>৳{data.calculate}</p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .line {
    &_left {
      p {
        padding: 2px 0;
        &:last-child,
        &:nth-child(2) {
          font-size: 16px;
          line-height: 100%;
          color: #f5ffff;
        }
        &.name {
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.05em;
          color: #669da2;
        }
      }
    }
    &_right {
      font-size: 28px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #2cc4b1;
      text-align: right;
      p:last-child {
        font-size: 16px;
      }
    }
  }
`;
