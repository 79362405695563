import React from "react";
import styled from "styled-components";
import RightCard from "./RightCard";

export default function Right({
  bill,
  setBill,
  BkashAgentCharge,
  BkashATMCharge,
  RocketAgentCharge,
  RocketATMCharge,
  NagadCharge,
  NagadIslamicCharge,
  showOption,
}) {
  const handleClick = () => {
    setBill(0);
  };

  const RIGHT = [
    {
      filter: "bkash",
      title: "Bkash Agent",
      calculate: BkashAgentCharge,
    },
    {
      filter: "bkash",
      title: "Bkash ATM",
      calculate: BkashATMCharge,
    },
    {
      filter: "rocket",
      title: "Rocket Agent",
      calculate: RocketAgentCharge,
    },
    {
      filter: "rocket",
      title: "Rocket ATM",
      calculate: RocketATMCharge,
    },
    {
      filter: "nagad",
      title: "Nagad Regular",
      calculate: NagadCharge,
    },
    {
      filter: "nagad",
      title: "Nagad Islamic",
      calculate: NagadIslamicCharge,
    },
  ];
  return (
    <Container>
      <div className="right_data">
        {RIGHT &&
          Array.isArray(RIGHT) &&
          RIGHT.length > 0 &&
          React.Children.toArray(
            showOption === "all"
              ? RIGHT.map((data) => <RightCard data={data} bill={bill} />)
              : showOption !== "blank" &&
                  RIGHT.filter((element) => element.filter === showOption).map(
                    (data) => <RightCard data={data} bill={bill} />
                  )
          )}
      </div>
      <button
        className={`button ${bill === 0 ? "disabled" : ""}`}
        onClick={handleClick}
      >
        RESET
      </button>
    </Container>
  );
}

const Container = styled.section`
  background-color: #00474b;
  padding: 53px 36px 39px 40px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
  .right_data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  button.button {
    background: #2cc4b1;
    color: #00474b;
    padding: 16px;
    width: 100%;
    border: none;
    outline: none;
    font-weight: bold;
    border-radius: 6px;
    &.disabled {
      background: #0d686d;
    }
    @media (max-width: 548px) {
      margin-top: 50px;
    }
  }
`;
